import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import FramedImage from "../components/FramedImage"
import { useIsMobile } from "../hooks/screen"
import SEO from "../components/seo"
import { usePageTransitionContext } from "../context/PageTransitionContext"

const Wrapper = styled.div`
  display: grid;
  width: 100vw;
  min-height: 100vh;
  height: 500px;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: 20% 1fr;
  }
`

const BackgroundImageWrapper = styled.div`
  opacity: 0;
  z-index: -1;
  /* display: none; */

  > figure {
    will-change: transform;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;

    .image {
      object-position: 70% center;
    }
  }

  @media (min-width: 768px) {
    display: block;
  }
`

const Content = styled.div`
  grid-column: 2 / 3;
  grid-row: 1;
  opacity: 0;
`

const Title = styled.h1`
  width: 250px;
  max-width: 100%;
  font-family: "Minion Pro";
  text-transform: uppercase;
  font-size: 14.2pt;
  letter-spacing: 0.25em;
  margin-bottom: 15px;
`

const Body = styled.p`
  width: 250px;
  max-width: 100%;
  font-weight: normal;
  font-family: "Minion Pro";
  font-size: 10.6pt;
  margin-bottom: 15px;
  line-height: 13.2pt;

  &:last-child {
    margin-bottom: 0;
  }
`

type Props = {
  data: {
    file: IGatsbyImageData
  }
}

const Publishing = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation()
  const loaded = useRef(false)
  const imageRef = useRef<HTMLDivElement | null>(null)
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useIsMobile()
  const { entering, exiting, timeElapsed } = usePageTransitionContext()

  useEffect(() => {
    const duration = (timeElapsed?.current ?? 1500) / 1000
    if (entering) {
      if (imageRef.current) {
        gsap.to(imageRef.current, {
          opacity: isMobile ? 0.7 : 1,
          duration: 0.1,
        })

        gsap.to(contentWrapperRef.current, {
          duration: duration - 0.1,
          ease: "power3.inOut",
          opacity: 1,
          delay: 0.1,
        })
      }
      loaded.current = true
    }

    if (exiting && loaded.current) {
      gsap.to(imageRef.current, { opacity: 0, duration })

      gsap.to(contentWrapperRef.current, {
        duration,
        ease: "power3.inOut",
        opacity: 0,
      })
    }
  }, [entering, exiting, timeElapsed, isMobile])

  return (
    <>
      <SEO title={t("publishing")} />
      <Wrapper>
        <BackgroundImageWrapper ref={imageRef}>
          <FramedImage image={data.file} active={entering} />
        </BackgroundImageWrapper>
        <Content ref={contentWrapperRef}>
          <Title>Film Talents</Title>
          <Body>
            <i>{t("publishing_1")}</i>
          </Body>
          <Body>{t("publishing_2")}</Body>
        </Content>
      </Wrapper>
    </>
  )
}

export default Publishing

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "publishing-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
